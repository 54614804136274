// src/utils/amazonAffiliate.js
import axios from 'axios';
import crypto from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

const accessKey = process.env.REACT_APP_AMAZON_ACCESS_KEY;
const secretKey = process.env.REACT_APP_AMAZON_SECRET_KEY;
const associateTag = process.env.REACT_APP_AMAZON_ASSOCIATE_TAG;
const region = process.env.REACT_APP_AMAZON_REGION || 'us-east-1';
const service = 'ProductAdvertisingAPI';

const endpoint = 'webservices.amazon.com';
const host = `https://${endpoint}`;

// Helper function to get ISO date in required format
const getFormattedDate = () => {
  return new Date().toISOString().replace(/[:-]|\.\d{3}/g, '');
};

// Helper function to generate a signature
const sign = (key, msg) => crypto.HmacSHA256(msg, key).toString(crypto.enc.Hex);

// Function to generate signed headers
const getSignatureKey = (key, dateStamp, regionName, serviceName) => {
  const kDate = sign('AWS4' + key, dateStamp);
  const kRegion = sign(kDate, regionName);
  const kService = sign(kRegion, serviceName);
  return sign(kService, 'aws4_request');
};

export const getAmazonProducts = async (keyword, maxResults = 5) => {
  const method = 'GET';
  const uri = '/onca/xml';
  const timestamp = getFormattedDate();
  const dateStamp = timestamp.slice(0, 8);

  const params = {
    'Service': 'AWSECommerceService',
    'Operation': 'ItemSearch',
    'AWSAccessKeyId': accessKey,
    'AssociateTag': associateTag,
    'SearchIndex': 'All',
    'Keywords': keyword,
    'ResponseGroup': 'Images,ItemAttributes,Offers',
    'Timestamp': timestamp
  };

  // Sort query parameters alphabetically
  const canonicalQueryString = Object.keys(params).sort()
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

  const canonicalRequest = [
    method,
    uri,
    canonicalQueryString,
    `host:${endpoint}`,
    '',
    `host`,
    crypto.SHA256('').toString()
  ].join('\n');

  const algorithm = 'AWS4-HMAC-SHA256';
  const credentialScope = `${dateStamp}/${region}/${service}/aws4_request`;
  const stringToSign = [
    algorithm,
    timestamp,
    credentialScope,
    crypto.SHA256(canonicalRequest).toString()
  ].join('\n');

  const signingKey = getSignatureKey(secretKey, dateStamp, region, service);
  const signature = sign(signingKey, stringToSign);

  // Final signed URL
  const requestUrl = `${host}${uri}?${canonicalQueryString}&Signature=${encodeURIComponent(signature)}`;

  try {
    const response = await axios.get(requestUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching Amazon products:', error);
    return [];
  }
};
