import React from 'react';

const PostBox = ({ post, onLike, onDislike }) => {
    return (
        <div className="post-box">
            <h2>{post.title}</h2>
            <p>{post.content.substring(0, 100)}...</p>
            <button onClick={() => window.location.href = `/post.html?id=${post.id}`}>Read More</button>
            <div className="thumbs">
                <button onClick={() => onLike(post.id)}>👍</button>
                <button onClick={() => onDislike(post.id)}>👎</button>
            </div>
        </div>
    );
};

export default PostBox;
