// src/pages/Blog.js
import React, { useState, useEffect } from 'react';
import SearchAndSort from '../components/SearchAndSort';
import AmazonSidebar from '../components/AmazonSidebar';
import axios from 'axios';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch all posts
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/posts');
        setPosts(response.data);
        setFilteredPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
      setLoading(false);
    };
    fetchPosts();
  }, []);

  // Search posts
  const handleSearch = (query) => {
    const filtered = posts.filter((post) =>
      post.title.toLowerCase().includes(query.toLowerCase()) ||
      post.content.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredPosts(filtered);
  };

  // Sort posts
  const handleSort = (sortType) => {
    let sorted = [...filteredPosts];
    switch (sortType) {
      case 'recent':
        sorted.sort((a, b) => b.id - a.id);
        break;
      case 'popular':
        sorted.sort((a, b) => b.likes - a.likes);
        break;
      default:
        break;
    }
    setFilteredPosts(sorted);
  };

  return (
    <div className="blog">
      <div className="main-content">
        <h1>Blog Posts</h1>
        <SearchAndSort onSearch={handleSearch} onSort={handleSort} />
        <div className="post-grid">
          {loading ? (
            <p>Loading posts...</p>
          ) : (
            filteredPosts.map((post) => (
              <div key={post.id} className="post-card">
                <h2>{post.title}</h2>
                <p>{post.content.substring(0, 100)}...</p>
                <button>Read More</button>
              </div>
            ))
          )}
        </div>
      </div>
      <AmazonSidebar />
    </div>
  );
};

export default Blog;
