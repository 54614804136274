// src/components/SearchAndSort.js
import React, { useState } from 'react';

const SearchAndSort = ({ onSearch, onSort }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    onSearch(query); // Pass the search query back to parent component
  };

  const handleSortChange = (e) => {
    const sortType = e.target.value;
    onSort(sortType); // Pass the sort type back to parent component
  };

  return (
    <div className="search-sort-bar">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <select onChange={handleSortChange}>
        <option value="recent">Most Recent</option>
        <option value="popular">Most Popular</option>
        <option value="likes">Most Liked</option>
      </select>
    </div>
  );
};

export default SearchAndSort;
