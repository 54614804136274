import React, { useState, useEffect } from 'react';

const AdminDashboard = () => {
    const [posts, setPosts] = useState([]);
    const [newPost, setNewPost] = useState({ title: '', content: '' });

    useEffect(() => {
        // Fetch existing posts when component loads
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        try {
            const response = await fetch('/api/admin/posts');
            const data = await response.json();
            setPosts(data);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    const handleCreatePost = async () => {
        try {
            await fetch('/api/admin/posts', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newPost)
            });
            fetchPosts(); // Refresh posts
        } catch (error) {
            console.error('Error creating post:', error);
        }
    };

    const handleDeletePost = async (postId) => {
        try {
            await fetch(`/api/admin/posts/${postId}`, { method: 'DELETE' });
            fetchPosts(); // Refresh posts
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    return (
        <div className="admin-dashboard">
            <h1>Admin Dashboard</h1>

            <div className="create-post">
                <h2>Create New Post</h2>
                <input
                    type="text"
                    placeholder="Post Title"
                    value={newPost.title}
                    onChange={(e) => setNewPost({ ...newPost, title: e.target.value })}
                />
                <textarea
                    placeholder="Post Content"
                    value={newPost.content}
                    onChange={(e) => setNewPost({ ...newPost, content: e.target.value })}
                />
                <button onClick={handleCreatePost}>Create Post</button>
            </div>

            <h2>Existing Posts</h2>
            <div className="post-list">
                {posts.map((post) => (
                    <div key={post.id} className="post-item">
                        <h3>{post.title}</h3>
                        <p>{post.content.substring(0, 100)}...</p>
                        <button onClick={() => handleDeletePost(post.id)}>Delete Post</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminDashboard;
