// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import PostBox from '../components/PostBox';
import AmazonSidebar from '../components/AmazonSidebar';
import axios from 'axios';

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  // Function to fetch blog posts (paginated)
  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/posts?page=${page}`);
      setPosts((prevPosts) => [...prevPosts, ...response.data]);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
    setLoading(false);
  };

  // Infinite scroll to fetch posts when user scrolls to bottom
  useEffect(() => {
    fetchPosts();
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        setPage((prevPage) => prevPage + 1);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [page]);

  return (
    <div className="home">
      <div className="main-content">
        <h1>Latest Blog Posts</h1>
        <div className="post-grid">
          {posts.map((post) => (
            <PostBox key={post.id} post={post} />
          ))}
        </div>
        {loading && <p>Loading more posts...</p>}
      </div>
      <AmazonSidebar />
    </div>
  );
};

export default Home;
