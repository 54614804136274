// src/components/AmazonSidebar.js
import React, { useState, useEffect } from 'react';
import { getAmazonProducts } from '../utils/amazonAffiliate';

const AmazonSidebar = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const result = await getAmazonProducts('laptops', 5); // Example keyword
      setProducts(result);
    };
    fetchProducts();
  }, []);

  return (
    <div className="side-panel">
      <h2>Amazon Deals</h2>
      <ul>
        {products.map((product, index) => (
          <li key={index}>
            <a href={product.DetailPageURL} target="_blank" rel="noopener noreferrer">
              <img src={product.Images?.Primary?.Large?.URL} alt={product.ItemInfo?.Title?.DisplayValue} />
              <p>{product.ItemInfo?.Title?.DisplayValue}</p>
              <p>Price: {product.Offers?.Listings?.[0]?.Price?.DisplayAmount}</p>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AmazonSidebar;
