import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <p>&copy; 2024 JagatabUK. All rights reserved.</p>
                <p>Privacy Policy | Terms & Conditions</p>
                <p>
                    Contact: <a href="mailto:info@jagatabuk.com">info@jagatabuk.com</a>
                </p>
                <div className="social-icons">
                    <a href="https://twitter.com/jagatabuk" target="_blank" rel="noopener noreferrer">
                        <img src="/images/twitter-icon.png" alt="Twitter" />
                    </a>
                    <a href="https://facebook.com/jagatabuk" target="_blank" rel="noopener noreferrer">
                        <img src="/images/facebook-icon.png" alt="Facebook" />
                    </a>
                    {/* Add more social icons as needed */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
